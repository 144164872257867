import * as React from "react";
import facebook from "../images/facebook.svg";
import twitter from "../images/twitter.svg";
import instagram from "../images/INSTA-ICON.svg";
import linkedin from "../images/linkedin.svg";
import newLogo from "../images/allegria-logo.jpg";
import { graphql, StaticQuery } from "gatsby";
import IndexJson from "../data/index.json";
import { Link } from "gatsby";

export const query = graphql`
  query footer {
    dataJson {
      phone_number
      address
      facebook_link
      linkedin_link
      email
    }
  }
`;

function Footer({ data }) {
  return (
    <div>
      <footer className="footer-container">
        <div className="footer-bg"></div>
        <div className="icon-logo-box">
          <div className="logo-footer-cnt">
            <img className="logo-footer" src={newLogo} alt=""></img>
            <div>
              <a href="tel:631-473-7100">
                <p>{IndexJson.phone_number}</p>
              </a>
              <p>{IndexJson.address}</p>
            </div>
          </div>
          <div className="social-icon-cnt">
            <a
              href={IndexJson.facebook_link}
              className="social-media-icon"
            >
              <img
                className="social-media-icon"
                alt="facebook"
                src={facebook}
              />
            </a>
            <a
              href={IndexJson.instagram_link}
              className="social-media-icon"
            >
              <img
                className="social-media-icon"
                alt="instagram"
                src={instagram}
              />
            </a>
            <a
              href={IndexJson.linkedin_link}
              className="social-media-icon"
            >
              <img
                className="social-media-icon"
                alt="linkedin"
                src={linkedin}
              />
            </a>
          </div>
        </div>
        <div className="copy-cnt">
        <div className="accretive-cpyright">
            <a href="https://accretivesoftware.com/" >
              <p>Website powered by Accretive Software LLC</p>
            </a>
          </div>
          <div className="accretive-cpyright privacy-policy">
            <Link to="/PrivacyPolicy/" >
              <p>Privacy Policy</p>
            </Link>
          </div>
          <div className="website-copyright">
            <p className="copy">© {new Date().getFullYear()} VJJ Holdings LLC</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
