import React from "react";
import MainNavBar from "./MainNavBar";
import { graphql, StaticQuery } from "gatsby";
import styles from "../styles/contactUs.css";
import Footer from "./Footer";
import { navigate } from "gatsby-link";
import jsonData from "./contact.json"

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
export const query = graphql`
  query contact {
    ourServicesGatsby: file(relativePath: { eq: "updated-our-services.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    staticMap {
      mapUrl
    }

    dataJson {
      phone_number
      address
      email      
      contact_us_title_deluxe
      contact_us_form_phone_deluxe
      contact_us_form_name_deluxe
      contact_us_form_email_deluxe
      contact_us_small_title_deluxe
    }
  }
`;

function ContactUs({ data }) {
  function encode(info) {
    return Object.keys(info)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(info[key])
      )
      .join("&");
  }

  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => alert("Thank you for submitting the form."))
      .catch((error) => alert(error));
  };

  const ourServicesGatsby = getImage(data.ourServicesGatsby);

  const x = 10;

  return (
    <div>
      <div>
        <MainNavBar />
      </div>
      <div className="about-us-cnt">
        <h1 className="about-us-title">
          {jsonData.contact_us_title_deluxe}
        </h1>
      </div>

      <section className="main-box-contact">
        <div className="main-about-us-cnt">
          <div className="img-card-amen">
            <h3 className="who-are-we">
              {jsonData.contact_us_small_title_deluxe}
            </h3>
            <div className="contact_info">
              <a href="tel:631-473-7100">
                <p>{data.dataJson.phone_number}</p>
              </a>
              {/* <p>{data.dataJson.contact_us_email_deluxe}</p> */}

              <p>{data.dataJson.address}</p>
            </div>
            <div className="map-container-contact">
              <div className="map-contact">
                {/* {data.staticMap.mapUrl} */}
                {/* <a href="https://www.google.com/maps/place/124+Cedarhurst+Ave,+Cedarhurst,+NY+11516/@40.6226303,-73.7242443,21z/data=!4m5!3m4!1s0x89c265bf61062b31:0x66e21b40a9d814de!8m2!3d40.6226114!4d-73.7241107"> */}
                <a href={data.staticMap.mapUrl} className="googleMap-serv">
                  <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=40.9149169,-73.035196&zoom=17&size=500x500&maptype=roadmap&key=AIzaSyA5mbyOAEau5yB_XzX6jusgU9Z5VKaR9BY"
                    alt="map"
                    className="imageHover-contact"
                  />
                </a>
              </div>
            </div>
            <div className="contact_form_contact">
              <form
                className="form_contact"
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="input-area">
                  <div className="input_contact">
                    <span className="remove-margin">
                      {" "}
                      {jsonData.contact_us_form_name_deluxe}
                    </span>
                    <input
                      className="reg-input contact-page-input"
                      // placeholder="Full Name"
                      type="text"
                      name="first-name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input_contact ">
                    <span className="remove-margin">
                      {" "}
                      {jsonData.contact_us_form_email_deluxe}
                    </span>
                    <input
                      className="reg-input contact-page-input"
                      // placeholder="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input_contact">
                    <span className="remove-margin">
                      {" "}
                      {jsonData.contact_us_form_phone_deluxe}
                    </span>
                    <input
                      className="reg-input contact-page-input"
                      // placeholder="Phone"
                      type="phone"
                      name="phone"
                      onChange={handleChange}
                    />
                  </div>
                  <textarea
                    name="message"
                    placeholder="Message"
                    className="textarea-serv"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="submit-btn-container contact-submit-button">
                  <button className="submit-btn">
                    <p className="submit-txt">Submit</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="image-about-us-box">
        <div className="image-about-us-cnt">
          <div className="image-card">
            <GatsbyImage
              className="image-about-us-newPage"
              placeholder="blurred"
              image={ourServicesGatsby}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ContactUs;
