import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

import styled from "styled-components";


const Navigation = styled.nav`
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 18px;
  align-items: center;
  max-width: 1300px;
  font-family: freight-sans-pro, sans-serif;
  align-self: center;
  justify-content: flex-end;

  @media screen and (max-width: 1080px) {
    width: 100%;
    height: 100%;
    font-size: 20px;
    top: 0;

    right: 0;
    justify-content: flex-end;
    left: 0;
  }
`;

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  width: 100%;
  @media screen and (max-width: 1080px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Navbox = styled.div`
  display: flex;
  width: 75%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    color: #ffffff;
    background-color: #fff;
    position: fixed;
    height: 100vh;
    width: 100%;
    justify-content: flex-start;
    transition: all 0.3s ease-in;
    top: 79px;
    z-index: 1;
    left: ${(props) => (props.open ? "-100%" : "0")};
  }
`;

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) =>
    props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? "0" : "1")};
    transform: ${(props) => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`;
const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  height: 8%;
  display: flex;
  flex-direction: space-between;
  align-items: center;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    height: 2px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: #111;
  }

  @media screen and (max-width: 1080px) {
    padding-bottom: 30px;
    color: #111;
    :hover {
      color: #111;
    }
  }
`;

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  // prevent scrolling when mobile menu is open
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = navbarOpen ? 'hidden' : 'auto';
  }, [navbarOpen])

  const scream = () => {
    console.log("sup");
  };
  return (
    <Navigation className={"CTN for Nav"}>
      {/* <div className="logo-style">
        <img className="logo-img" src={newLogo}></img>
      </div> */}
      <Toggle
        className={"thisTheToggle"}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox className={"thisTheNavBox"}>
          <NavItem className={"thisIsANavItem"}>
            <Link to="/AboutUs/" onClick={() => setNavbarOpen(!navbarOpen)}>
              <p>About Us</p>
            </Link>
          </NavItem>
          <NavItem className={"thisIsANavItem"}>
            <Link to="/Services/" onClick={() => setNavbarOpen(!navbarOpen)}>
              <p>Services</p>
            </Link>
          </NavItem>
          <NavItem className={"thisIsANavItem"}>
            <Link to="/#insurance" onClick={() => setNavbarOpen(!navbarOpen)}>
              <p>Insurance</p>
            </Link>
          </NavItem>
          <NavItem className={"thisIsANavItem"}>
            <Link to="/#amenities" onClick={() => setNavbarOpen(!navbarOpen)}>
              <p>Amenities</p>
            </Link>
          </NavItem>
          <NavItem className={"thisIsANavItem"}>
            <Link to="/news/" onClick={() => setNavbarOpen(!navbarOpen)}>
              <p>News and Events</p>
            </Link>
          </NavItem>
          <NavItem className={"thisIsANavItem"}>
            <Link to="/ContactUs/" onClick={() => setNavbarOpen(!navbarOpen)}>
              <p>Contact Us</p>
            </Link>
          </NavItem>
        </Navbox>
      ) : (
        <Navbox open>
          <NavItem>
            <Link to="/#about" onClick={scream}>
              About Us
            </Link>
          </NavItem>
          {/* </Link> */}
          <NavItem>
            <Link to="/#service">Services</Link>
          </NavItem>
          <NavItem>
            <Link to="/#insurance">Insurance</Link>
          </NavItem>
          <NavItem>
            <Link to="/#amenities">Amenities</Link>
          </NavItem>
          <NavItem>
            <Link to="/#contact">Contact Us</Link>
          </NavItem>
        </Navbox>
      )}
    </Navigation>
  );
};

export default Navbar;
