import * as React from "react";
import styles from "../styles/index.css";
import newLogo from "../images/allegria-logo.jpg";
import { Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby-link";
import NavBar from "./NavBar";

function MainNavBar() {
  return (
    <div className="nav-bar">
      <div className="helps-width-nav">
        <div className="logo-cnt">
          <Link to="/">
            <img className="logo" src={newLogo}></img>
          </Link>
        </div>
        <div className="nav-list">
          <div className="desktopNavBar">
            <ul className="nav">
              <li>
                <Link
                  to="/AboutUs/"
                  className="navbar_link"
                  activeClassName="navbar_link--active"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/Services/"
                  className="navbar_link"
                  activeClassName="navbar_link--active"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/#amenities"
                  className="navbar_link"
                  activeClassName="navbar_link--active"
                >
                  Amenities
                </Link>
              </li>
              <li>
                <Link
                  to="/#insurance"
                  className="navbar_link"
                  activeClassName="navbar_link--active"
                >
                  Insurance
                </Link>
              </li>
              <li>
                <Link
                  to="/news"
                  className="navbar_link"
                  activeClassName="navbar_link--active"
                >
                  News and Events
                </Link>
              </li>
              <li>
                <Link
                  to="/ContactUs/"
                  className="navbar_link"
                  activeClassName="navbar_link--active"
                >
                  Contact Us
                </Link>
              </li>
              {/* <li>
                 <Link to="#contact">
                   <a href="tel:5555555555">555-555-5555</a>
                 </Link>
               </li> */}
            </ul>
          </div>
          <div className="mobileNavBar">
            <NavBar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainNavBar;
